import { useErrorHandlers } from "@/errors/components/error-handling-context";
import {
  selectRegistrationAlgorithm,
  selectRegistrationAlgorithmSettings,
} from "@/registration-tools/common/store/registration-parameters/registration-parameters-selectors";
import { Features } from "@/store/features/features";
import { selectHasFeature } from "@/store/features/features-slice";
import { useAppSelector, useAppStore } from "@/store/store-hooks";
import { redirectToDataManagementUrl } from "@/utils/redirects";
import {
  ArrowDown2Icon,
  FaroButton,
  FaroMenu,
  FaroMenuItem,
} from "@faro-lotv/flat-ui";
import { GUID } from "@faro-lotv/foundation";
import { selectDashboardUrl } from "@faro-lotv/project-source";
import {
  ProjectApi,
  RegistrationRevision,
  RegistrationState,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { ButtonGroup } from "@mui/material";
import { useCallback, useState } from "react";
import {
  selectCaptureTreeEdges,
  selectHasSomeEntityTransformOverride,
  selectIsRegistrationEdgeValid,
  selectUpdatedRevisionEntities,
  UpdatedRevisionEntities,
} from "../store/revision-selectors";

type RefineAndPublishButtonProps = {
  /** The currently active revision. */
  revision: RegistrationRevision;
};

/** @returns A button to confirm the registration results, run a refined registration in the cloud and then publish the data set. */
export function RefineAndPublishButton({
  revision,
}: RefineAndPublishButtonProps): JSX.Element {
  const dashboardUrl = useAppSelector(selectDashboardUrl);
  const [isRefinementRunning, setIsRefinementRunning] = useState(false);

  const { registrationApiClient, projectApiClient } = useApiClientContext();
  const { getState } = useAppStore();
  const { handleErrorWithToast } = useErrorHandlers();
  const isUserModified = useAppSelector(selectHasSomeEntityTransformOverride);

  const isEnabled =
    revision.state === RegistrationState.started || isUserModified;

  const hasRegistrationDevFeature = useAppSelector(
    selectHasFeature(Features.RegistrationDev),
  );

  const registrationAlgorithm = useAppSelector(selectRegistrationAlgorithm);
  const registrationSettings = useAppSelector(
    selectRegistrationAlgorithmSettings,
  );

  const saveUserEdits = useCallback(async () => {
    if (!isUserModified) return;

    // Set the state to user modified to enable changes
    await projectApiClient.updateRegistrationRevision({
      registrationRevisionId: revision.id,
      state: RegistrationState.userModified,
      // Remove the registration report, as it is not valid for the new scan positioning
      reportUri: null,
    });

    const updatedEntities = selectUpdatedRevisionEntities(getState());
    await persistUserEdits(projectApiClient, revision.id, updatedEntities);

    // Delete edges which are invalidated by user edits
    const registrationEdges = selectCaptureTreeEdges(getState());
    const invalidEdgeIds = registrationEdges
      .filter(
        (edge) =>
          !selectIsRegistrationEdgeValid(
            edge.sourceId,
            edge.targetId,
          )(getState()),
      )
      .map((edge) => edge.id);
    if (invalidEdgeIds.length) {
      await projectApiClient.markRegistrationEdgeRevisionsAsDeleted(
        revision.id,
        invalidEdgeIds,
      );
    }

    // A redirect happens after the refinement is run, so the local state doesn't need to be updated
  }, [projectApiClient, revision.id, getState, isUserModified]);

  const runRefinement = useCallback(async () => {
    try {
      await registrationApiClient?.startCaptureTreeRegistration({
        revisionId: revision.id,
        registrationAlgorithm: hasRegistrationDevFeature
          ? registrationAlgorithm
          : undefined,
        parameters: hasRegistrationDevFeature
          ? registrationSettings
          : undefined,
      });

      redirectToDataManagementUrl(projectApiClient.projectId, dashboardUrl);
    } catch (error) {
      handleErrorWithToast({
        error,
        title: "Failed to Run Refinement",
      });
    }
  }, [
    dashboardUrl,
    handleErrorWithToast,
    hasRegistrationDevFeature,
    registrationAlgorithm,
    registrationApiClient,
    registrationSettings,
    revision.id,
    projectApiClient.projectId,
  ]);

  const confirmAndRun = useCallback(async () => {
    setIsRefinementRunning(true);

    try {
      await saveUserEdits();
    } catch (error) {
      handleErrorWithToast({
        error,
        title: "Failed to save edited registration",
      });
      setIsRefinementRunning(false);
      return;
    }

    try {
      await runRefinement();
    } catch (error) {
      handleErrorWithToast({
        error,
        title: "Failed to run refinement",
      });
    }

    setIsRefinementRunning(false);
  }, [runRefinement, saveUserEdits, handleErrorWithToast]);

  const [dropdownButtonRef, setDropdownButtonRef] =
    useState<HTMLButtonElement | null>(null);
  const [areAdditionalOptionsOpen, setAreAdditionalOptionsOpen] =
    useState(false);

  return (
    <>
      <ButtonGroup sx={{ flexBasis: "content", flexShrink: 0 }}>
        <FaroButton
          variant="primary"
          disabled={!isEnabled}
          isLoading={isRefinementRunning}
          onClick={confirmAndRun}
        >
          Refine & publish
        </FaroButton>
        <FaroButton
          variant="primary"
          disabled={!isEnabled}
          onClick={() => setAreAdditionalOptionsOpen(!areAdditionalOptionsOpen)}
          ref={setDropdownButtonRef}
          sx={{ p: 0, width: "auto" }}
        >
          <ArrowDown2Icon />
        </FaroButton>
      </ButtonGroup>

      <FaroMenu
        open={areAdditionalOptionsOpen}
        onClose={() => setAreAdditionalOptionsOpen(false)}
        anchorEl={dropdownButtonRef}
        sx={{ mt: 1 }}
      >
        <FaroMenuItem
          label="Publish without refinement"
          // Waiting for backend support https://faro01.atlassian.net/browse/NRT-1496
          disabled
        />
      </FaroMenu>
    </>
  );
}

/**
 * Persist the user edits to the project API.
 *
 * @param projectApiClient The API client to use.
 * @param registrationRevisionId The ID of the revision that needs to be updated.
 * @param updatedEntities The entities that need to be updated.
 */
async function persistUserEdits(
  projectApiClient: ProjectApi,
  registrationRevisionId: GUID,
  updatedEntities: UpdatedRevisionEntities,
): Promise<void> {
  const { updatedRoot, updatedClusters, updatedScans } = updatedEntities;

  // Update the entities on the API
  if (updatedRoot) {
    await projectApiClient.createOrUpdateRootEntityForRegistrationRevision({
      registrationRevisionId,
      requestBody: updatedRoot,
    });
  }

  if (updatedClusters.length) {
    await projectApiClient.createOrUpdateClusterEntitiesForRegistrationRevision(
      {
        registrationRevisionId,
        requestBody: updatedClusters,
      },
    );
  }

  if (updatedScans.length) {
    await projectApiClient.createOrUpdateScanEntitiesForRegistrationRevision({
      registrationRevisionId,
      requestBody: updatedScans,
    });
  }
}
