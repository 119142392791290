import { AppAwareHtml } from "@/components/r3f/renderers/app-aware-html";
import { PlaceholderPreviewBase } from "@/components/r3f/utils/placeholder-preview-base";
import { useImg360PreviewSignedUrl } from "@/hooks/use-img360-preview-signed-url";
import { RegistrationMetrics } from "@/registration-tools/common/registration-report/registration-metrics";
import { THRESHOLD_SET_STATIONARY } from "@/registration-tools/common/registration-report/registration-thresholds";
import {
  ThresholdSetProvider,
  useThresholdSetContext,
} from "@/registration-tools/common/registration-report/threshold-set-context";
import { colorForIndex } from "@/registration-tools/common/rendering/use-point-cloud-materials";
import {
  QualityStatus,
  RegistrationDetails,
} from "@/registration-tools/utils/metrics";
import {
  MultiRegistrationReport,
  reportScans,
} from "@/registration-tools/utils/multi-registration-report";
import { Features, selectHasFeature } from "@/store/features/features-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import {
  ColorString,
  FaroDialog,
  FaroSvgIcon,
  TranslateVar,
  dataComparisonColorsSorted,
  green,
  neutral,
  red,
  yellow,
} from "@faro-lotv/flat-ui";
import { LineMaterial } from "@faro-lotv/lotv";
import {
  RegistrationEdgeRevision,
  RegistrationEdgeType,
  RegistrationRevision,
  RevisionScanEntity,
  RevisionStatus,
} from "@faro-lotv/service-wires";
import { Box } from "@mui/material";
import { useCursor } from "@react-three/drei";
import { Size, useThree } from "@react-three/fiber";
import {
  MutableRefObject,
  RefObject,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Intersection,
  Line3,
  Mesh,
  Raycaster,
  Vector2,
  Vector3,
  Vector3Tuple,
} from "three";
import { LineSegmentsGeometry } from "three-stdlib";
import { ReactComponent as ScanMarkerWarningSvg } from "../icons/registration-marker-warning.svg";
import { ReactComponent as ScanMarkerSvg } from "../icons/registration-marker.svg";
import { useLazyLoadRevisionImg360 } from "../loading/use-load-revision-img360s";
import {
  selectIsHoveringEntity,
  selectSelectedEntityId,
} from "../store/data-preparation-ui/data-preparation-ui-selectors";
import {
  setHoveredEntityId,
  setSelectedEntityId,
  unsetHoveredEntityId,
} from "../store/data-preparation-ui/data-preparation-ui-slice";
import {
  selectQualityColorCoding,
  selectShowConnectionLines,
} from "../store/data-preparation-view-options/data-preparation-view-options-selectors";
import {
  selectActiveRegistrationEdges,
  selectIsEntityVisibleRecursive,
  selectIsIdInFilteredDisjunctGroups,
  selectIsRegistrationEdgeVisible,
  selectRevisionEntityScan,
  selectRevisionEntityWorldTransformCache,
} from "../store/revision-selectors";

/** The width of the connection lines. */
const LINE_WIDTH = 5;

/** Size of a scan placeholder. */
const PLACEHOLDER_SIZE = 22;

/** The size factor when the element is being hovered. */
const HOVER_FACTOR = 1.5;

/** The size factor when the element is not selected or hovered. */
const UNSELECTED_FACTOR = 0.7;

/** The color of connection line when user chooses quality color to be disabled */
const DISABLED_QUALITY_COLOR = neutral[700];

/** The color of connection line whose quality is not known */
const UNKNOWN_QUALITY_COLOR = neutral[700];

/** Render placeholders above connection lines. */
enum RenderOrder {
  connections = 1,
  placeholders = 2,
}

type RegistrationConnectionsProps = {
  /** The scan entities to show the connections for. */
  scanEntities: RevisionScanEntity[];
  /** The portal element to place the HTML entities inside of. */
  portal?: MutableRefObject<HTMLElement>;
};

/** @returns Visual representation of the registration connections. */
export function RegistrationConnections({
  scanEntities,
  portal,
}: RegistrationConnectionsProps): JSX.Element | null {
  const activeEdges = useAppSelector(selectActiveRegistrationEdges);
  const showConnectionLines = useAppSelector(selectShowConnectionLines);

  return (
    <>
      {showConnectionLines && (
        <>
          {activeEdges.map((edge) => (
            <ConnectionLine
              registrationEdge={edge}
              key={edge.id}
              portal={portal}
            />
          ))}
        </>
      )}
      {scanEntities.map((scan, index) => (
        <ScanMarker
          key={scan.id}
          scanEntity={scan}
          color={colorForIndex(index, dataComparisonColorsSorted)}
          portal={portal}
        />
      ))}
    </>
  );
}

/** Add an offset to the popper to position it just below the scan marker. */
const POPPER_MODIFIERS = [
  {
    name: "offset",
    options: {
      offset: [0, (PLACEHOLDER_SIZE * HOVER_FACTOR) / 2],
    },
  },
];

/**
 * The limit of the z-index range for the scan markers.
 *
 * The limit needs to be sufficiently big so that the markers can be properly mapped according to their 3D position.
 */
const MARKER_Z_INDEX_LIMIT = 10_000_000;

/** A z-index value to place tooltips on top of the scan markers. */
const TOOLTIP_Z_INDEX = MARKER_Z_INDEX_LIMIT + 1;

type ScanMarkerProps = {
  /** The scan to create a marker for. */
  scanEntity: RevisionScanEntity;
  /** The color representation of the scan. */
  color: ColorString;
  /** The portal element to place the HTML entities inside of. */
  portal?: MutableRefObject<HTMLElement>;
};

/** @returns A marker for the scan position. */
function ScanMarker({
  scanEntity,
  color,
  portal,
}: ScanMarkerProps): JSX.Element | null {
  const dispatch = useAppDispatch();
  const position = useScanPosition(scanEntity);
  const isVisible = useAppSelector(
    selectIsEntityVisibleRecursive(scanEntity.id),
  );
  const selectedEntity = useAppSelector(selectSelectedEntityId);
  const isSelected = !selectedEntity || selectedEntity === scanEntity.id;

  const isHovering = useAppSelector(selectIsHoveringEntity(scanEntity.id));
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  // When hovering, load the pano image captured by the scanner to show as a preview
  const img360 = useLazyLoadRevisionImg360(scanEntity, isHovering);
  const previewSignedUrl = useImg360PreviewSignedUrl(img360);

  const isDisjunctScan = useAppSelector((state) =>
    selectIsIdInFilteredDisjunctGroups(state, scanEntity.id),
  );

  const toggleSelection = useCallback(
    () =>
      dispatch(
        setSelectedEntityId(
          selectedEntity === scanEntity.id ? undefined : scanEntity.id,
        ),
      ),
    [dispatch, scanEntity.id, selectedEntity],
  );

  const size = useMemo(() => {
    if (isHovering) {
      return PLACEHOLDER_SIZE * HOVER_FACTOR;
    }
    if (!isSelected) {
      return PLACEHOLDER_SIZE * UNSELECTED_FACTOR;
    }
    return PLACEHOLDER_SIZE;
  }, [isHovering, isSelected]);

  if (!isVisible) return null;

  return (
    <>
      <AppAwareHtml
        position={position}
        center
        style={{ margin: 0, padding: 0, lineHeight: 0 }}
        renderOrder={RenderOrder.placeholders}
        zIndexRange={[MARKER_Z_INDEX_LIMIT, 0]}
        portal={portal}
      >
        <Box component="div">
          {isDisjunctScan ? (
            <FaroSvgIcon
              source={ScanMarkerWarningSvg}
              onPointerEnter={() => dispatch(setHoveredEntityId(scanEntity.id))}
              onPointerLeave={() =>
                dispatch(unsetHoveredEntityId(scanEntity.id))
              }
              onClick={toggleSelection}
              sx={{
                width: size,
                height: size,
                color: neutral[900],
                cursor: "pointer",
              }}
            />
          ) : (
            <FaroSvgIcon
              source={ScanMarkerSvg}
              onPointerEnter={() => dispatch(setHoveredEntityId(scanEntity.id))}
              onPointerLeave={() =>
                dispatch(unsetHoveredEntityId(scanEntity.id))
              }
              onClick={toggleSelection}
              sx={{
                width: size,
                height: size,
                color: isSelected || isHovering ? color : neutral[500],
                cursor: "pointer",
              }}
            />
          )}
        </Box>
      </AppAwareHtml>
      {/* The pano preview is in a separate HTML wrapper to ensure that it's always positioned above all other scan markers */}
      {isHovering && (
        <AppAwareHtml
          position={position}
          style={{
            margin: 0,
            padding: 0,
            lineHeight: 0,
          }}
          renderOrder={RenderOrder.placeholders}
          // Ensure that the preview is on top of the scan markers
          zIndexRange={[TOOLTIP_Z_INDEX, TOOLTIP_Z_INDEX]}
          portal={portal}
        >
          <Box component="div" ref={setAnchorEl} />
          <PlaceholderPreviewBase
            name={scanEntity.name}
            createdAt={scanEntity.createdAt}
            imageUri={previewSignedUrl}
            isVisible={isHovering}
            anchorEl={anchorEl}
            popperModifiers={POPPER_MODIFIERS}
          />
        </AppAwareHtml>
      )}
    </>
  );
}

type ConnectionLineProps = {
  /** The registration edge to create a line for. */
  registrationEdge: RegistrationEdgeRevision;

  /** The portal element to place the HTML entities inside of. */
  portal?: MutableRefObject<HTMLElement>;

  /** Whether the line should be non-interactable. */
  nonInteractable?: boolean;
};

/** @returns A line representing a cloud-to-cloud registration. */
function ConnectionLine({
  registrationEdge,
  portal,
}: ConnectionLineProps): JSX.Element | null {
  const { thresholdSet } = useThresholdSetContext();

  const { sourceId, targetId, data } = registrationEdge;

  const sourceEntity = useAppSelector(selectRevisionEntityScan(sourceId));
  const targetEntity = useAppSelector(selectRevisionEntityScan(targetId));
  const isEdgeVisible = useAppSelector(
    selectIsRegistrationEdgeVisible(sourceId, targetId),
  );

  const sourcePosition = useScanPosition(sourceEntity);
  const targetPosition = useScanPosition(targetEntity);

  const [isHovering, setIsHovering] = useState(false);
  const [isDetailsDialogOpen, setIsDetailsDialogOpen] = useState(false);

  const showQualityColorCoding = useAppSelector(selectQualityColorCoding);
  const hasRegistrationDevFeature = useAppSelector(
    selectHasFeature(Features.RegistrationDev),
  );
  useCursor(isHovering);

  // Determine the line color based on the quality of the registration
  const color = useMemo(() => {
    // Unknown quality if we don't have metrics
    if (!data?.metrics) return UNKNOWN_QUALITY_COLOR;

    const quality = new RegistrationDetails(
      data.metrics.overlap,
      thresholdSet,
      data.metrics.rlyHistogram,
    ).registrationQuality;

    switch (quality) {
      case QualityStatus.GOOD:
        return green[500];
      case QualityStatus.MEDIUM:
        return yellow[500];
      case QualityStatus.POOR:
        return red[500];
      case QualityStatus.UNKNOWN:
        return UNKNOWN_QUALITY_COLOR;
    }
  }, [data, thresholdSet]);

  const width = isHovering ? LINE_WIDTH * HOVER_FACTOR : LINE_WIDTH;

  if (!sourceEntity || !targetEntity || !isEdgeVisible) {
    return null;
  }

  return (
    <>
      {/* Outline for better contrast */}
      <ConnectionLineSegment
        sourcePosition={sourcePosition}
        targetPosition={targetPosition}
        color={neutral[1000]}
        width={width + 0.5}
        onClick={() => {
          if (showQualityColorCoding) {
            setIsDetailsDialogOpen(true);
            if (hasRegistrationDevFeature) {
              console.debug("Clicked edge debug data:", data);
            }
          }
        }}
        onHoverChange={(isHovering) => {
          if (showQualityColorCoding) {
            setIsHovering(isHovering);
          }
        }}
      />
      {/* Actual connection line */}
      <ConnectionLineSegment
        sourcePosition={sourcePosition}
        targetPosition={targetPosition}
        color={showQualityColorCoding ? color : DISABLED_QUALITY_COLOR}
        width={width}
      />
      <AppAwareHtml portal={portal}>
        {/* TODO: Properly use the outer threshold set provider */}
        <ThresholdSetProvider defaultThresholdSet={thresholdSet}>
          <FaroDialog
            title={
              <>
                Quality View:{" "}
                <TranslateVar name="source-scan">
                  {sourceEntity.name}
                </TranslateVar>{" "}
                -{" "}
                <TranslateVar name="target-scan">
                  {targetEntity.name}
                </TranslateVar>
              </>
            }
            open={isDetailsDialogOpen}
            onClose={() => setIsDetailsDialogOpen(false)}
            size="l"
            sx={{ zIndex: TOOLTIP_Z_INDEX }}
          >
            {data?.metrics?.overlap && data.metrics.rlyHistogram && (
              <RegistrationMetrics
                overlap={data.metrics.overlap}
                rlyHistogram={data.metrics.rlyHistogram}
                thresholdSet={THRESHOLD_SET_STATIONARY}
              />
            )}
          </FaroDialog>
        </ThresholdSetProvider>
      </AppAwareHtml>
    </>
  );
}

type ConnectionLineSegmentProps = {
  /** The start position of the line. */
  sourcePosition: Vector3Tuple;
  /** The end position of the line. */
  targetPosition: Vector3Tuple;
  /** The color of the line. */
  color: ColorString;
  /** The width of the line. */
  width: number;
  /** The action to execute when the user clicks on the line. */
  onClick?(): void;
  /** The action to execute when the user hovers on the line. */
  onHoverChange?(isHovering: boolean): void;
};

/** @returns A colored line segment with raycast support. */
function ConnectionLineSegment({
  sourcePosition,
  targetPosition,
  color,
  width,
  onClick,
  onHoverChange,
}: ConnectionLineSegmentProps): JSX.Element {
  const geometry = useMemo(() => {
    const g = new LineSegmentsGeometry();
    g.setPositions([...sourcePosition, ...targetPosition]);
    return g;
  }, [sourcePosition, targetPosition]);

  const canvasSize = useThree((state) => state.size);
  const resolution = useMemo(
    () => new Vector2(canvasSize.width, canvasSize.height),
    [canvasSize.height, canvasSize.width],
  );

  const mesh = useRef<Mesh>(null);
  const material = useRef<LineMaterial>(null);

  const raycast = useLineRaycast(
    mesh,
    sourcePosition,
    targetPosition,
    width,
    canvasSize,
  );

  return (
    <mesh
      renderOrder={RenderOrder.connections}
      ref={mesh}
      onClick={(event) => {
        if (onClick) {
          event.stopPropagation();
          onClick();
        }
      }}
      onPointerEnter={(event) => {
        if (onHoverChange) {
          event.stopPropagation();
          onHoverChange(true);
        }
      }}
      onPointerLeave={(event) => {
        if (onHoverChange) {
          event.stopPropagation();
          onHoverChange(false);
        }
      }}
      // eslint-disable-next-line react/no-unknown-property
      raycast={raycast}
    >
      <primitive object={geometry} attach="geometry" />
      <lineMaterial
        ref={material}
        color={color}
        transparent
        depthTest={false}
        depthWrite
        /* eslint-disable react/no-unknown-property */
        worldUnits={false}
        linewidth={width}
        resolution={resolution}
        alphaToCoverage={false}
        /* eslint-enable react/no-unknown-property */
      />
    </mesh>
  );
}

/**
 * Raycast implementation for line meshes.
 * The line mesh has 0 width, so a custom implementation is needed.
 *
 * @param lineMesh The line mesh to raycast.
 * @param sourcePosition The source position of the line.
 * @param targetPosition The target position of the line.
 * @param lineWidth The width of the line.
 * @param canvasSize The size of the canvas.
 * @returns The custom raycast implementation.
 */
function useLineRaycast(
  lineMesh: RefObject<Mesh>,
  sourcePosition: Vector3Tuple,
  targetPosition: Vector3Tuple,
  lineWidth: number,
  canvasSize: Size,
): Mesh["raycast"] {
  const canvasSizeVec3 = useMemo(
    () => new Vector3(canvasSize.width, canvasSize.height, 0),
    [canvasSize],
  );

  // Cached allocations
  const sourceScreenspace = useRef(new Vector3());
  const targetScreenspace = useRef(new Vector3());
  const pointerScreenspace = useRef(new Vector3());
  const closestPoint = useRef(new Vector3());
  const line = useRef(new Line3());

  // The line geometry has 0 width, so we need a custom raycast implementation
  return useCallback(
    (raycaster: Raycaster, intersects: Array<Intersection<Mesh>>) => {
      const { camera } = raycaster;

      // Project everything into screenspace
      sourceScreenspace.current
        .fromArray(sourcePosition)
        .project(camera)
        .multiply(canvasSizeVec3);
      targetScreenspace.current
        .fromArray(targetPosition)
        .project(camera)
        .multiply(canvasSizeVec3);
      pointerScreenspace.current
        .copy(raycaster.ray.origin)
        .project(camera)
        .multiply(canvasSizeVec3);

      // Check if the distance is smaller than the line width
      line.current.set(sourceScreenspace.current, targetScreenspace.current);
      line.current.closestPointToPoint(
        pointerScreenspace.current,
        true,
        closestPoint.current,
      );
      const distance = closestPoint.current.distanceTo(
        pointerScreenspace.current,
      );

      if (lineMesh.current && distance <= lineWidth) {
        intersects.push({
          distance,
          object: lineMesh.current,
          point: closestPoint.current,
        });
      }
    },
    [lineMesh, sourcePosition, targetPosition, lineWidth, canvasSizeVec3],
  );
}

/**
 * @param report The registration report to get the connections from.
 * @param revision The revision that the connections are for.
 * @returns All scan connections that have been registered.
 */
export function reportConnections(
  report: MultiRegistrationReport,
  revision: RegistrationRevision,
): Array<RegistrationEdgeRevision & { type: RegistrationEdgeType.global }> {
  return reportScans(report).flatMap((scan) =>
    scan.registrations.map((registration) => ({
      id: registration.registrationObjectId,
      createdBy: revision.createdBy,
      createdAt: revision.createdAt,
      lastPatchedBy: revision.createdBy,
      lastPatchedAt: revision.createdAt,
      status: RevisionStatus.added,
      sourceId: scan.uuid,
      targetId: registration.targetScanId,
      type: RegistrationEdgeType.global,
      data: {
        jsonRevision: 3,
        metrics: registration.metrics,
      },
    })),
  );
}

/**
 * @param scanEntity The scan entity to get the position for.
 * @returns The position where the scanner was located during the scan.
 */
function useScanPosition(scanEntity?: RevisionScanEntity): Vector3Tuple {
  const revisionTransform = useAppSelector(
    selectRevisionEntityWorldTransformCache(scanEntity?.id),
  );
  return revisionTransform.position;
}
